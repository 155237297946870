// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
// 引入element UI
import ElementUI from "element-ui";
// import "./style/theme/index.css"
import "element-ui/lib/theme-chalk/index.css";
import "../static/style/theme/index.css";

import "./assets/css/index.css";
import App from "./App";
// 引入路由
import router from "./router";
// 引入状态管理
import store from "./vuex/store";

// 引入icon
import "./assets/icon/iconfont.css";
import "./style/iconfont/iconfont.css";
//引入初始化样式
//引入i18n
import i18n from "./lang/index";

import JsonExcel from "vue-json-excel";

Vue.component("downloadExcel", JsonExcel);
// import jquery from "jquery";
// Vue.prototype.$ = jquery;
import defaultSetting from "./setting";

// 引入echarts
import echarts from "echarts";
Vue.prototype.$echarts = echarts;

import axios from "axios";
Vue.prototype.$axios = axios;

Vue.config.productionTip = false;

// 使用element UI,并切换
Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value)
});

import Icon from "vue2-svg-icon/Icon.vue";

Vue.component("icon", Icon);

// 过滤器
import * as custom from "./utils/util";
import { fnAddDynamicMenuRoutes } from "./utils/getAsyncRouter";
import NProgress from "nprogress";
import "nprogress/nprogress.css";

Object.keys(custom).forEach(key => {
  Vue.filter(key, custom[key]);
});

NProgress.configure({
  easing: "ease", // 动画方式
  speed: 500, // 递增进度条的速度
  showSpinner: false, // 是否显示加载ico
  trickleSpeed: 200, // 自动递增间隔
  minimum: 0.3 // 初始化时的最小百分比
});

// 路由拦截器
let isToken = true;
// 定义一个白名单
const WHITE_LIST = ["/login", "/notFound"];
router.beforeEach((to, from, next) => {
  // 用于设置 浏览器的 title 显示
  if (to.name) {
    document.title = `${to.name}-${defaultSetting.title}`;
  } else {
    document.title = `${defaultSetting.title}`;
  }
  let userdata = window.sessionStorage.getItem("userdata");
  if (isToken && userdata) {
    const menu_list = JSON.parse(userdata).menu;
    const routes = fnAddDynamicMenuRoutes(menu_list);
    routes.forEach(item => {
      if (item.children && item.children.length > 0) {
        item["redirect"] = item.children[0].path;
        item.children.forEach(item => {
          if (item.children.length > 0) {
            item["redirect"] = item.children[0].path;
          }
        });
      }
      router.addRoute("首页", item);
    });
    store.state.menus = menu_list;
    isToken = false; //将isToken赋为 false ，否则会一直循环，崩溃
    next({
      path: to.path, // next({ ...to })的目的,是保证路由添加完了再进入页面 (可以理解为重进一次)
      replace: true // 重进一次, 不保留重复历史
    });
  }
  const token = window.sessionStorage.getItem("logintoken");
  if (token) {
    NProgress.start();
    //  3.1 判断当前访问的页面是否是 login
    if (to.path === "/login") {
      // 是：跳转到首页
      router.push("/home/workbench");
    } else {
      // 不是：向后执行
      next();
    }
  } else {
    const bool = WHITE_LIST.includes(to.path);
    if (bool) {
      next();
    } else {
      router.push("/login");
      NProgress.done();
    }
  }
});
router.afterEach(() => {
  NProgress.done();
});
//引入注册全局组件
import libs from "./components/libs/index";

Vue.use(libs);
/* eslint-disable no-new */
new Vue({
  el: "#app",
  router,
  store, //使用store vuex状态管理
  i18n,
  render: h => h(App)
}).$mount("#app");

<template>
  <div>
    <el-row>
      <el-col :span="3">
        <div style="display: flex; justify-content: start; align-items: center; height: 60px; margin-left: 16px">
          <img alt="" class="logoimg" src="../assets/img/logo1.png" />
        </div>
      </el-col>
      <el-col :span="16" class="menus">
        <el-menu
          :default-active="activeIndex"
          active-text-color="#2e82ff"
          background-color="#fff"
          class="el-menu-demo"
          collapse-transition
          mode="horizontal"
          router
          text-color="#909399"
        >
          <el-menu-item
            v-for="item in menu"
            :key="item.path"
            :index="item.redirect || item.path"
            style="font-size: 16px"
            @click="clickMenu(item)"
          >
            <template>
              <i :class="item.icon"></i>
              <span>{{ item.title }}</span>
            </template>
          </el-menu-item>
        </el-menu>
      </el-col>
      <el-col :span="5" style="display: flex; justify-content: space-evenly; align-items: center">
        <div class="submenu language" style="width: 60px; height: 60px; line-height: 60px; cursor: pointer">
          <el-tooltip class="screen" content="全屏缩放" effect="dark" fullscreen placement="bottom">
            <i class="el-icon-rank" style="color: #000; font-size: 18px" @click="isScreenFull"></i>
          </el-tooltip>
        </div>
        <Remind />
        <div>
          <el-dropdown>
            <span class="el-dropdown-link">
              <i class="iconfont icon-lvzhou_yuyanqiehuan" style="font-size: 20px" @click="isScreenFull"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click="changeLang('zn')">中文</el-dropdown-item>
              <el-dropdown-item @click="changeLang('en')">English</el-dropdown-item>
              <el-dropdown-item @click="changeLang('ko')">한국인</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div style="color: #1d2974">
          {{ warehouse.warehouse.warehouse_title }}
          <el-dropdown>
            <span class="el-dropdown-link">
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="(item, index) in warehouse.warehouse_lis"
                :key="index"
                @click.native="changeWarehouse(item)"
                >{{ item.warehouse_title }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div style="display: flex; align-items: center; justify-content: space-evenly">
          <span style="margin-right: 25px; color: #1d2974">{{ warehouse.info.name }}</span>
          <el-avatar src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"></el-avatar>
          <el-dropdown>
            <span class="el-dropdown-link">
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="editPwd">修改密码</el-dropdown-item>
              <!--            <el-dropdown-item>个人中心</el-dropdown-item>-->
              <el-dropdown-item @click.native="exit()">退出</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-col>
    </el-row>
    <el-dialog :visible.sync="dialogFormVisible" title="修改密码" width="30%">
      <el-form ref="form" :model="form" :rules="rules">
        <el-form-item
          :label-width="formLabelWidth"
          :rules="[{ required: true, message: '请输入原密码', trigger: 'blur' }]"
          label="原密码"
          prop="opass"
        >
          <el-input v-model="form.opass" autocomplete="off" placeholder="请输入原密码"></el-input>
        </el-form-item>
        <el-form-item :label-width="formLabelWidth" label="新密码" prop="npassone">
          <el-input
            v-model.trim="form.npassone"
            autocomplete="new-password"
            placeholder="请输入新密码"
            type="password"
          ></el-input>
        </el-form-item>
        <el-form-item :label-width="formLabelWidth" label="确认密码" prop="npasstwo">
          <el-input
            v-model.trim="form.npasstwo"
            autocomplete="off"
            placeholder="请再次输入新密码"
            type="password"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('form')">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import screenfull from "screenfull";
  import store from "../vuex/store";
  import Remind from "@/components/Remind.vue";
  import { changePassword, logout, unbindMembeId } from "@/api/login";
  import { changeWh } from "@/api/api";
  import { getClientId } from "@/utils/auth";

  export default {
    name: "NavCon",
    components: { Remind },
    data() {
      const checkPassword = (rule, value, callback) => {
        if (!value) {
          return callback(new Error("密码不能为空"));
        }
        if (this.form.npasstwo != "") {
          this.$refs.form.validateField("npasstwo");
        }
        callback();
      };
      const validateConfirmPwd = (rule, value, callback) => {
        if (value === "") {
          callback(new Error("请再次输入密码"));
        } else if (value !== this.form.npassone) {
          callback(new Error("两次输入密码不一致!"));
        } else {
          callback();
        }
      };

      return {
        form: {
          opass: "",
          npassone: "",
          npasstwo: ""
        },
        rules: {
          npassone: [
            { required: true, message: "请输入新密码", trigger: ["change", "blur"] },
            { validator: checkPassword, trigger: ["change", "blur"] }
          ],
          npasstwo: [
            { required: true, message: "请再次输入新密码", trigger: ["change", "blur"] },
            {
              validator: validateConfirmPwd,
              trigger: ["change", "blur"]
            }
          ]
        },
        dialogTableVisible: false,
        dialogFormVisible: false,
        formLabelWidth: "120px",
        collapsed: true,
        imgshow: require("../assets/img/show1.png"),
        imgsq: require("../assets/img/sq1.png"),
        isFullscreen: false,
        activeIndex: store.state.active,
        menu: [],
        warehouse: JSON.parse(sessionStorage.getItem("userdata"))
      };
    },
    // 创建完毕状态(里面是操作)
    created() {
      this.menu = this.$store.state.menus;
    },
    methods: {
      logout() {
        logout().then(res => {
          console.log(res);
        });
      },
      clickMenu(item) {
        if (item.path === "/home/workbench") return;
        this.$store.commit("pushtags", item.children[0]);
      },
      // 退出登录
      exit() {
        this.$confirm("退出登录, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            setTimeout(async () => {
              await unbindMembeId({ client_id: getClientId().client_id });
              this.logout();
              this.$store.commit("logout", "false");
              this.$router.push({ path: "/login" });
              this.$message({
                type: "success",
                message: "已退出,请重新登录!"
              });
              // window.location.reload()
            }, 1000);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消"
            });
          });
      },
      // 切换显示
      toggle(showtype) {
        this.collapsed = !showtype;
        this.$root.Bus.$emit("toggle", this.collapsed);
      },
      changeLang(val) {
        if (this.$i18n.locale !== val) {
          this.$i18n.locale = val;
          localStorage.setItem("lang", val);
        } else {
          return false;
        }
      },
      changeWarehouse(item) {
        changeWh({ warehouse_id: item.warehouse_id }).then(res => {
          if (res.status === 200) {
            this.$message({
              type: "success",
              message: res.msg
            });
            let data = JSON.parse(sessionStorage.getItem("userdata"));
            const newData = {
              ...data,
              warehouse: res.data
            };
            sessionStorage.setItem("userdata", JSON.stringify(newData));
            location.reload();
          }
        });
      },
      //全屏方法
      isScreenFull() {
        // 需要注意的是 如果判断!screenfull.enabled 显示的是不支持全屏的话 是因为谷歌的版本问题  判断改为 !screenfull.isEnabled  就可以了
        console.log(screenfull);
        if (!screenfull.isEnabled) {
          // 如果不支持进入全屏，发出不支持提示
          this.$message({
            message: "您的浏览器版本过低不支持全屏显示！",
            type: "warning"
          });
          return false;
        }
        screenfull.toggle();
      },
      //修改密码
      editPwd() {
        this.form = {
          opass: "",
          npassone: "",
          npasstwo: ""
        };
        this.dialogFormVisible = true;
      },
      submitForm(formName) {
        this.$refs[formName].validate(valid => {
          if (valid) {
            this.changePassword(this.form);
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      },
      changePassword(obj) {
        changePassword(obj).then(res => {
          if (res.status === 200) {
            this.$message({
              type: "success",
              message: res.msg
            });
            this.dialogFormVisible = false;
            setTimeout(() => {
              this.logout();
              this.$store.commit("logout", "false");
              this.$router.push({ path: "/login" });
              this.$message({
                type: "success",
                message: "已退出,请重新登录!"
              });
            }, 1000);
          } else {
            this.$message({
              type: "error",
              message: res.msg
            });
          }
        });
      }
    }
  };
</script>

<style lang="scss" scoped>
  .el-menu-vertical-demo:not(.el-menu--collapse) {
    border: none;
  }

  .logoimg {
    height: 35px;
  }

  .submenu {
    float: right;
  }

  .language {
    ::v-deep .el-icon-arrow-down:before {
      content: "";
    }
  }

  .screen {
    line-height: 60px;
    float: right;
  }

  .buttonimg {
    height: 60px;
    background-color: transparent;
    border: none;
  }

  .showimg {
    width: 22px;
    height: 22px;
    position: absolute;
    top: 19px;
    left: 26px;
  }

  .showimg:active {
    border: none;
  }

  .language ::v-deep .el-icon-arrow-down:before {
    display: none;
  }

  .menus {
    display: flex;
    justify-content: center;
  }

  ::v-deep .el-menu--horizontal > .el-menu-item {
    margin: 0 20px;
    padding: 0 10px;
  }
</style>
